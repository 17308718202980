import { Ref, ref } from 'vue'
import { defineStore } from 'pinia'
import createHttpClient from '@/api/httpClient'
import {
  AppointmentReasonInformationData,
  FormattedAppointmentReasonInformationData,
} from '@/api/appointment-reason/appointment-reason'
import { ApiResponse } from '@/api/api'

const httpClient = createHttpClient()

export const useAppointmentReasonStore = defineStore(
  'appointment-reason',
  () => {
    const appointmentReasons: Ref<FormattedAppointmentReasonInformationData[]> =
      ref([])

    // Fonction pour charger les raisons de rendez-vous depuis localStorage
    const loadFromCache = () => {
      const cachedData = localStorage.getItem('appointmentReasons')
      if (cachedData) {
        try {
          appointmentReasons.value = JSON.parse(cachedData)
          appointmentReasons.value = JSON.parse(cachedData).map(motive => ({
            ...motive,
            remote: motive.remote ? 'yes' : 'no',
          })) as FormattedAppointmentReasonInformationData[]

          console.log(appointmentReasons.value)
        } catch (error) {
          console.error('Failed to parse cached data:', error)
        }
      }
    }

    const fillAppointmentReasons = async () => {
      try {
        // Vérifie si les données sont déjà en cache
        if (appointmentReasons.value.length > 0) {
          return
        }

        // Charge les données depuis l'API
        const res: ApiResponse<AppointmentReasonInformationData[]> =
          await httpClient.get('/appointment-reason/me')
        if (res.status !== 200) throw Error(JSON.stringify(res.data))

        // Formater les données pour les utiliser dans l'application
        const formattedData = res.data.map(motive => ({
          ...motive,
          remote: motive.remote ? 'yes' : 'no',
        })) as FormattedAppointmentReasonInformationData[]

        appointmentReasons.value = res.data.map(motive => ({
          ...motive,
          remote: motive.remote ? 'yes' : 'no',
        })) as FormattedAppointmentReasonInformationData[]

        // Stocker les données dans localStorage pour une utilisation ultérieure
        localStorage.setItem(
          'appointmentReasons',
          JSON.stringify(formattedData),
        )
      } catch (error) {
        console.error('Failed to fetch appointment reasons:', error)
      }
    }

    const refreshAppointmentReasons = async () => {
      try {
        // Charge les données depuis l'API
        const res: ApiResponse<AppointmentReasonInformationData[]> =
          await httpClient.get('/appointment-reason/me')
        if (res.status !== 200) throw Error(JSON.stringify(res.data))

        // Formater les données pour les utiliser dans l'application
        const formattedData = res.data.map(motive => ({
          ...motive,
          remote: motive.remote ? 'yes' : 'no',
        })) as FormattedAppointmentReasonInformationData[]

        appointmentReasons.value = res.data.map(motive => ({
          ...motive,
          remote: motive.remote ? 'yes' : 'no',
        })) as FormattedAppointmentReasonInformationData[]

        localStorage.setItem(
          'appointmentReasons',
          JSON.stringify(formattedData),
        )
      } catch (error) {
        console.error('Failed to fetch appointment reasons:', error)
      }
    }

    loadFromCache()

    return {
      appointmentReasons,
      fillAppointmentReasons,
      refreshAppointmentReasons,
    }
  },
)
