<template>
  <div class="d-flex">
    <ScheduleXCalendar
      v-resize="onResize"
      :custom-components="customComponents"
      :calendar-app="calendarApp"
    />
    <DialogAppointment />
    <DialogVacation />
    <DialogDetails />
  </div>
</template>

<script lang="ts" setup>
import '@holitime/schedule-x-theme-default/dist/index.css'
import { ScheduleXCalendar } from '@holitime/schedule-x-vue'
import { calendarApp } from '@/components/calendar/calendarApp'
import { CustomComponents } from '@holitime/schedule-x-shared'
import CustomSidebarWrapper from '@/components/calendar/CustomSidebarWrapper.vue'
import { useDisplay } from 'vuetify'
import {
  useHeaderState,
  useSidebarState,
} from '@/components/calendar/calendarState'
import CustomTimeGridEvent from '@/components/calendar/CustomTimeGridEvent.vue'
import DialogVacation from '@/components/calendar/DialogVacation.vue'
import DialogAppointment from '@/components/calendar/DialogAppointment.vue'
import DialogDetails from '@/components/calendar/DialogDetails.vue'
import { onMounted } from 'vue'
import { usePractitionerAppointmentStore } from '@/store/practitioner-appointment/practitionerAppointmentStore'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import CustomMonthGridEvent from '@/components/calendar/CustomMonthGridEvent.vue'
import createHttpClient from '@/api/httpClient'
import { ApiResponse } from '@/api/api'
const httpClient = createHttpClient()
import { useRouter } from 'vue-router'
import EventBus from '@/utils/eventBus'
import { useUserStore } from '@/store/user/userStore'
import { useAddressStore } from '@/store/address/addressStore'
import { useProfileStore } from '@/store/profile/profileStore'
import { useDialogTimeSlotStore } from '@/store/timeslot/dialogTimeSlotStore'
import { useAppointmentReasonStore } from '@/store/appointment-reason/appointementReasonStore'
import { useVacationEventStore } from '@/store/vacation/vacationEventStore'

const appointmentReasonStore = useAppointmentReasonStore()
const userStore = useUserStore()
const addressStore = useAddressStore()
const dialogStore = useDialogTimeSlotStore()
const profileStore = useProfileStore()
const vacationStore = useVacationEventStore()

const customComponents: CustomComponents = {
  sidebar: CustomSidebarWrapper as unknown as any,
  // dateGridEvent: CustomDateGridEvent as unknown as any,
  timeGridEvent: CustomTimeGridEvent as unknown as any,
  monthGridEvent: CustomMonthGridEvent as unknown as any,
}

const { lgAndUp } = useDisplay()
const { sidebarState } = useSidebarState()

const onResize = () => {
  const calendarContainer = document.querySelector('.sx__calendar-content')
  if (!calendarContainer) return
  if (lgAndUp.value && sidebarState.value) {
    calendarContainer.classList.remove('hidden-sidebar')
  }
  if (
    lgAndUp.value &&
    calendarContainer.classList.contains('hidden-sidebar') &&
    !sidebarState.value
  ) {
    calendarContainer.classList.add('hidden-sidebar')
  }
  if (!lgAndUp.value) {
    calendarContainer.classList.remove('hidden-sidebar')
  }
}

const practitionerAppointmentStore = usePractitionerAppointmentStore()
const route = useRoute()

const headerState = useHeaderState()
const { t } = useI18n()
const router = useRouter()

// interface GoogleConnectResponse {
//   url: string
// }

// const authorizeGoogleCalendar = async () => {
//   try {
//     const resp: ApiResponse<GoogleConnectResponse> =
//       await httpClient.get('/connect/google')
//     if (resp.status != 200) throw Error(JSON.stringify(resp.data))
//     console.log(resp.data)
//     window.location.href = resp.data?.url
//   } catch (error) {
//     console.error(error)
//   }
// }

// const handleGoogleCallback = async () => {
//   try {
//     // Extraire le code d'authentification de l'URL
//     const query = new URLSearchParams(window.location.search)
//     const code = query.get('code')

//     if (code) {
//       // Envoyer le code d'authentification au backend pour obtenir les informations utilisateur
//       const resp = await httpClient.get(
//         `/connect/google/check?code=${encodeURIComponent(code)}`,
//       )
//       if (resp.status != 200) throw new Error(JSON.stringify(resp.data))

//       console.log('Google OAuth callback processed successfully')

//       // Rediriger l'utilisateur après le traitement
//       router.push('/dashboard/appointment') // Ou vers une autre route
//     }
//   } catch (error) {
//     console.log('Error during Google OAuth callback:', error)
//   }
// }

EventBus.on('reInitAppointments', () => {
  practitionerAppointmentStore.initAppointments()
})

onMounted(() => {
  // if(userStore.user.profilType === 'patient'){
  //   return router.push({ name: 'DashboardAccount' })
  // }

  // handleGoogleCallback()
  practitionerAppointmentStore.initAppointments()
  if (route.name === 'DashboardTimeSlot') {
    headerState.updateHeaderState(t('dashboard.menu.appointment'))
  }
  if (route.name === 'DashboardAppointment') {
    headerState.updateHeaderState(t('dashboard.menu.timeslot'))
  }

  profileStore.refreshProfiles()
  addressStore.refreshAddresses()
  appointmentReasonStore.refreshAppointmentReasons()
  dialogStore.resetDialogTimeSlotCreationProfileAddress()
  addressStore.refreshAddresses()
  vacationStore.refreshVacations()
})
</script>

<style lang="scss">
@import url('./styleCalendar.css');
</style>
