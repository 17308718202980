import { computed, ref, watch } from 'vue'
import { defineStore } from 'pinia'
import { ApiResponse } from '@/api/api'
import createHttpClient from '@/api/httpClient'
import {
  IPractitionerPublicProfile,
  IProfileAddress,
  PractitionerPublicProfileInterface,
} from './practitionerPublicProfile.d'
import { latLng } from 'leaflet'
import { AddressInformationData } from '@/api/account/address'
import MessageService from '@/components/feedback/message/messageService'
import i18n from '@/plugins/i18n'
import { LABEL_VERIFIED_DEGREES } from '@/shared/constants'
import { useAppointmentManagementStore } from './appointmentManagementStore'
import { IFeedback, feedbackData } from '@/components/feedback/lists/feedback'

const { t } = i18n.global

export const usePractitionerPublicProfileStore = defineStore(
  'practitioner-public-profile',
  () => {
    const httpClient = createHttpClient()
    const distanceFromPatient = ref<string>('')
    const profile = ref<IPractitionerPublicProfile>(null)
    const nextAvailability = ref<string>('')
    const isLoading = ref(false)
    const feedbacks = ref<IFeedback>()
    const opinionSearch = ref('')
    const orderBy = ref<'asc' | 'desc'>('desc')
    const slug = ref('')
    const limit = ref(10)
    const page = ref(1)
    const total = ref(1)
    const loadFeedback = ref(false)

    const fetchProfile = async (
      slug: string | string[],
      disciplineSlug: string | string[],
    ) => {
      try {
        const response: ApiResponse<PractitionerPublicProfileInterface> =
          await httpClient.get(
            `/profile/public?slug=${slug}&discipline=${disciplineSlug}`,
          )
        if (response.status !== 200) {
          throw Error('Error occurred on get addresses')
        }
        if (!response.data) {
          profile.value = null
          return
        }

        let allProfiles: any = []
        if (response.data && response.data.id) {
          allProfiles = await fetchOtherProfiles(response.data.id)
        }

        // Format response
        profile.value = {
          ...response.data,
          nextAvailability: nextAvailability.value ?? '', // this is filled from the search page
          verified: response.data.labels
            ? response.data.labels.includes(LABEL_VERIFIED_DEGREES)
            : false,
          profileAddresses: response.data.profileAddresses.map(
            (address): IProfileAddress => ({
              id: address.id,
              address: {
                ...address.address,
                formattedAddress: address.address.remote
                  ? t('practitionerPublicProfile.remoteLabel')
                  : `${address.address.addressLine}, ${address.address.postalCode} ${address.address.city}, ${address.address.country}`,
              },
            }),
          ),
          allProfiles: allProfiles,
        }

        const appointmentManagementStore = useAppointmentManagementStore()
        appointmentManagementStore.remote = profile.value.remote
          ? 'remote'
          : 'in-person'
      } catch (error) {
        console.error(error)
        profile.value = null
        return
      }
    }

    const fetchOtherProfiles = async (ownerId: string) => {
      try {
        const response: ApiResponse<PractitionerPublicProfileInterface> =
          await httpClient.get(`/profile/practician/${ownerId}`)
        if (response.status !== 200) {
          throw Error('Error occurred on get addresses')
        }
        if (!response.data) {
          profile.value = null
          return
        }
        return response.data
      } catch (error) {
        console.error(error)
        profile.value = null
        return
      }
    }

    const computedAddresses = computed<AddressInformationData[]>(() => {
      if (
        !profile.value ||
        !profile.value.profileAddresses ||
        !profile.value.profileAddresses.length
      )
        return []
      return profile.value.profileAddresses.map(address => ({
        ...address.address,
        latLng: latLng(address.address.latitude, address.address.longitude),
      }))
    })

    const optinions = async (v: string) => {
      if (loadFeedback.value) return
      try {
        loadFeedback.value = true
        slug.value = v

        const res: ApiResponse<feedbackData> = await httpClient.get(
          `/profile/reviews?slug=${slug.value}&page=${page.value}&search=${opinionSearch.value}&order=${orderBy.value}`,
        )

        const items = res.data

        feedbacks.value = items.data
      } catch (err) {
      } finally {
        loadFeedback.value = false
      }
    }

    const appendFeedback = () => {}

    // watch([orderBy.value, opinionSearch.value], async () => {
    //   optinions(slug.value)
    // })

    watch(
      () => [orderBy.value, opinionSearch.value],
      async () => {
        optinions(slug.value)
      },
    )

    return {
      isLoading,
      distanceFromPatient,
      profile,
      fetchProfile,
      nextAvailability,
      computedAddresses,
      optinions,
      feedbacks,
      opinionSearch,
      orderBy,
      loadFeedback,
    }
  },
)
