import { Ref, ref } from 'vue'
import { defineStore } from 'pinia'
import createHttpClient from '@/api/httpClient'
import { ApiResponse } from '@/api/api'
import { AddressInformationData } from '@/api/account/address'
import { formatAddress } from '@/utils/formatAddress'

const httpClient = createHttpClient()

export enum FormMode {
  HIDDEN = 'hidden',
  ADD = 'add',
  EDIT = 'edit',
}

export const useAddressStore = defineStore('address', () => {
  const addresses: Ref<AddressInformationData[]> = ref([])
  const displayAddrForm = ref(false)
  const totalAddr = ref(0)

  const formMode: Ref<FormMode> = ref(FormMode.HIDDEN)

  // Fonction pour charger les adresses depuis le cache (localStorage)
  const loadFromCache = () => {
    const cachedData = localStorage.getItem('addresses')
    if (cachedData) {
      try {
        addresses.value = JSON.parse(cachedData).map(address => ({
          ...address,
          formattedAddress: formatAddress(address, true),
        }))
      } catch (error) {
        console.error('Failed to parse cached address data:', error)
      }
    }
  }

  // Fonction pour remplir les adresses en récupérant depuis l'API
  const fillAddresses = async () => {
    try {
      // Si les adresses sont déjà chargées en mémoire, ne pas refaire la requête
      if (addresses.value.length > 0) {
        return
      }

      const res: ApiResponse<AddressInformationData[]> = await getAddresses()
      if (res.status !== 200) throw Error(JSON.stringify(res.data))

      // Remplir les adresses dans le state
      addresses.value = res.data.map(address => ({
        ...address,
        formattedAddress: formatAddress(address, true),
      }))

      // Stocker les données dans localStorage
      localStorage.setItem('addresses', JSON.stringify(addresses.value))
    } catch (error) {
      console.error('Failed to fetch addresses:', error)
    }
  }

  // Fonction pour rafraîchir les adresses (force la récupération depuis l'API)
  const refreshAddresses = async () => {
    try {
      const res: ApiResponse<AddressInformationData[]> = await getAddresses()
      if (res.status !== 200) throw Error(JSON.stringify(res.data))

      // Remplir les adresses dans le state
      addresses.value = res.data.map(address => ({
        ...address,
        formattedAddress: formatAddress(address, true),
      }))

      // Mettre à jour le cache (localStorage)
      localStorage.setItem('addresses', JSON.stringify(addresses.value))
    } catch (error) {
      console.error('Failed to refresh addresses:', error)
    }
  }

  // Initialisation du store (chargement depuis le cache puis remplissage)
  const initAddresses = async () => {
    try {
      // Charger les données depuis le cache d'abord
      loadFromCache()

      // Si le cache est vide, remplir les adresses depuis l'API
      if (addresses.value.length === 0) {
        await fillAddresses()
      }
    } catch (error) {
      console.error('Failed to initialize addresses:', error)
    }
  }

  return {
    addresses,
    initAddresses,
    refreshAddresses,
    totalAddr,
    displayAddrForm,
    formMode,
  }
})

// Fonction pour récupérer les adresses depuis l'API
const getAddresses = async (): Promise<
  ApiResponse<AddressInformationData[]>
> => {
  const response =
    await httpClient.get<AddressInformationData[]>('/account/address')
  return {
    status: response.status,
    data: response.data,
  }
}
