import { Ref, ref } from 'vue'
import { defineStore } from 'pinia'
import createHttpClient from '@/api/httpClient'
import { AxiosResponse } from 'axios'
import { calendarApp } from '@/components/calendar/calendarApp'
import MessageService from '@/components/feedback/message/messageService'
import { useI18n } from 'vue-i18n'
import { VacationData, VacationEvent } from '@/store/vacation/vacation'
import dayjs from 'dayjs'

export const useVacationEventStore = defineStore('vacation-event', () => {
  const httpClient = createHttpClient()
  const { t } = useI18n()
  const vacations: Ref<VacationEvent[]> = ref([])
  const empty_vacations: Ref<VacationData[]> = ref([])

  // Fonction pour charger les vacances depuis le cache (localStorage)
  const loadFromCache = () => {
    const cachedData = localStorage.getItem('vacations')
    if (cachedData) {
      try {
        vacations.value = JSON.parse(cachedData)
      } catch (error) {
        console.error('Failed to parse cached vacation data:', error)
      }
    }
  }

  // Fonction pour formater les données de vacances
  const formatResponse = (vacationsData: VacationData[]): VacationEvent[] => {
    return vacationsData.map(v => {
      const start = dayjs(v.startDate).format('YYYY-MM-DD HH:mm')
      const end = dayjs(v.endDate).format('YYYY-MM-DD HH:mm')
      return {
        id: v.id,
        title: t('dashboard.time-slot.dialogVacation.vacation'),
        start,
        end,
        type: 'VacationEvent',
      }
    })
  }

  // Fonction pour remplir les vacances en récupérant depuis l'API
  const fillVacations = async () => {
    try {
      // Si les vacances sont déjà chargées en mémoire, ne pas refaire la requête
      if (vacations.value.length > 0) {
        return
      }

      const response: AxiosResponse<VacationData[]> =
        await httpClient.get(`/vacation/all`)
      if (response.status !== 200) {
        throw Error(JSON.stringify(response))
      }

      // Formater les données et les affecter
      vacations.value = formatResponse(response.data)

      // Stocker les données dans localStorage
      localStorage.setItem('vacations', JSON.stringify(vacations.value))
    } catch (error) {
      console.error('Failed to fetch vacation events:', error)
    }
  }

  // Fonction pour rafraîchir les vacances en forçant la récupération depuis l'API
  const refreshVacations = async () => {
    try {
      const response: AxiosResponse<VacationData[]> =
        await httpClient.get(`/vacation/all`)
      if (response.status !== 200) {
        throw Error(JSON.stringify(response))
      }

      // Formater les données et les affecter
      vacations.value = formatResponse(response.data)

      // Mettre à jour le cache (localStorage)
      localStorage.setItem('vacations', JSON.stringify(vacations.value))
    } catch (error) {
      console.error('Failed to refresh vacation events:', error)
    }
  }

  // Initialisation du store (chargement depuis le cache puis remplissage)
  const initVacations = async () => {
    try {
      await refreshVacations()
      // // Charger les données depuis le cache d'abord
      // loadFromCache()

      // // Si le cache est vide, remplir les vacances depuis l'API
      // if (vacations.value.length === 0) {
      //   await refreshVacations()
      //   // vacations.value  = formatResponse(empty_vacations.value);
      // }
    } catch (error) {
      MessageService.error(t('dashboard.time-slot.errorGetTimeSlot'))
    }
  }

  return {
    vacations,
    initVacations,
    refreshVacations,
  }
})
